import React, { useState, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

export default function Countdown({
    fontSize,
    fontFamily,
    fontColor,
    fontWeight,
    displayDays,
    displayHours,
    displayMinutes,
    displaySeconds,
    endDate,
    endTime,
    endMessage,
}) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft())
        }, 1000)

        return () => clearTimeout(timer)
    })

    function calculateTimeLeft() {
        const difference = +new Date(`${endDate}T${endTime}`) - +new Date()
        let timeLeft = {}

        if (difference > 0) {
            timeLeft = {
                days: displayDays
                    ? Math.floor(difference / (1000 * 60 * 60 * 24))
                    : null,
                hours: displayHours
                    ? Math.floor((difference / (1000 * 60 * 60)) % 24)
                    : null,
                minutes: displayMinutes
                    ? Math.floor((difference / 1000 / 60) % 60)
                    : null,
                seconds: displayMinutes
                    ? Math.floor((difference / 1000) % 60)
                    : null,
            }
        }

        return timeLeft
    }

    const timerComponents = []

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return
        }

        timerComponents.push(
            <span
                key={interval}
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                {timeLeft[interval]}
                <span style={{ fontSize: `${fontSize / 2.5}px` }}>
                    {interval.toLowerCase()}
                </span>
            </span>
        )
    })

    return (
        <div
            style={{
                fontSize,
                fontFamily,
                color: fontColor,
                fontWeight,
                display: "flex",
                gap: "20px",
            }}
        >
            {timerComponents.length ? (
                timerComponents
            ) : (
                <span>{endMessage}</span>
            )}
        </div>
    )
}

addPropertyControls(Countdown, {
    fontSize: {
        type: ControlType.Number,
        unit: "px",
        defaultValue: 30,
    },
    fontFamily: {
        type: ControlType.String,
        defaultValue: "Inter",
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "black",
    },
    fontWeight: {
        type: ControlType.Enum,
        defaultValue: 400,
        options: [
            "normal",
            "bold",
            "bolder",
            "lighter",
            100,
            200,
            300,
            400,
            500,
            600,
            700,
            800,
            900,
        ],
    },
    displayDays: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    displayHours: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    displayMinutes: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    displaySeconds: {
        type: ControlType.Boolean,
        defaultValue: true,
    },
    endDate: {
        type: ControlType.String,
        defaultValue: "2023-04-25",
        description: "YYYY-MM-DD",
    },
    endTime: {
        type: ControlType.String,
        defaultValue: "00:00:00",
        description: "HH:MM:SS",
    },
    endMessage: {
        type: ControlType.String,
        defaultValue: "Time's up!",
    },
})
